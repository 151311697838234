@import "./variables";

.font-xs {
  font-size: 1rem;
}

.font-reg {
  font-size: $font-size-sm;
}

.font-md {
  font-size: $font-size-md;
}

@media screen and (min-width: $screen-md-min) {
  .font-md--tablet {
    font-size: $font-size-md;
  }
}

.font-lg {
  font-size: $font-size-lg;
}

.font-xl {
  font-size: $font-size-xl;
}

.font-blue {
  color: $blue-link;
}

.font-red {
  color: $red;
}

.font-light {
  color: $grey-light;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.point:hover {
  cursor: pointer;
}

.focus-lightBlue:focus,
.hover-lightBlue:hover {
  background-color: $blue-light !important;
  color: $white !important;
}

.pad-1 {
  padding: 1rem;
}

.red {
  color: $red;
}

@media screen and (min-width: calc(#{$screen-md-max} + 1px)) {
  .show-md {
    display: flex;
    justify-content: space-between;
  }
  .hide-md {
    display: none;
  }
}

@media screen and (max-width: $screen-md-max) {
  .show-md {
    display: none;
  }

  .hide-md {
    display: block;
  }
}

.vaS {
  vertical-align: super;
}