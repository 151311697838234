@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700&display=swap');
@import "./resources/styles/App.scss";

* {
  margin: 0;
  padding: 0;
}


body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

//this causes scrollbar is not visible
// ::-webkit-scrollbar {
//   display: none;
// }

body {
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  background-color: $bg-color;
  font-size: 2rem;
}

p:not(.logo),
li {
  font-size: 1.8rem;
}

@media screen and (max-width: $screen-md-max) {
  body {
    font-size: 1.6rem;
  }
}

:focus {
  outline-color: $blue !important;
  outline-width: 0.2rem;
  box-shadow: none !important;
}
