$bg-color: #2f395c0f;
$green: #14bb05de;
$blue: #0068acff;
$blue-button: #0f4874;
$navy: #444b6eff;
$blue-half-transparent: #0e83c731;
$dark: #292c44;
$dark-half-transparent: #25273b46;
$white: #f7f7f7;
$blue-light: #0591c9fd;
$color-active: #27b4c7f5;
$grey-light: #bfcede;
$black: #2e282a;
$red: #ef476f;
$blue-link: #0153a0;
$blue-link-active: #0068acff;
$blue-link-hover: #1a78a8;
$blue-light: #00a0ed;
$blend: #4b4b6e;

$screen-xs-max: 375px;
$screen-sm-max: 425px;
$screen-md-min: 576px;
$screen-md-max: 768px;

$font-size-sm: 1.4rem;
$font-size-reg: 1.7rem;
$font-size-md: 1.9rem;
$font-size-lg: 2.1rem;
$font-size-xl: 2.4rem;
$font-size-mega: 4.8rem;
$font-size-giga: 6.2rem;
$font-size-tera: 7rem;

$shadow-lg: 0 0.4rem 0.3rem -0.3rem $navy;
$shadow-md: 0 0.1rem 0.1rem -0.1rem $blue;

$transition-link: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
